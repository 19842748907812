@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,700;1,400&display=swap);
/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
/* Use border-box sizing by default */
html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}
/* Use default tab */
:root {
  tab-size: 4;
}
/* default to system fonts */
body {
  font-family: "-apple-system", "system-ui", "BlinkMacSystemFont", "Segoe UI",
    "Roboto", "Helvetica Neue", "Arial", "sans-serif", "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol";
}

* {
  box-sizing: border-box;
}

html {
  /* Colors */
  --color-primary: #991b20;
  --color-secondary: #001e2d;

  --color-action-primary: #efc97b;
  --color-action-secondary: #ffffff;

  --font-family-default: "Expressway";
  --font-weight-default: 500;

  --color-text-primary: #ffffff;
  --color-text-secondary: #001e2d;
  --color-text-default: #ffffff;
  --color-text-disabled: #6e6e6e;
  --color-text-input: #000000;

  --color-fill-error: #ffc6c6;
  --color-text-error: #d62d0a;
  --color-text-success: #297f2c;

  --color-border-error: #e45c52;
  --color-border-default: #6e6e6e;

  /* Settings */
  --border-radius: 4px;
}

body {
  font-family: "Expressway";
  font-family: var(--font-family-default);
  font-weight: 400;
}

img {
  max-width: 100%;
  height: auto;
}

a {
  color: white;
}

button {
  font-family: "Expressway";
  font-family: var(--font-family-default);
  font-weight: 300;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  line-height: 135%;
  font-family: "Expressway";
  font-family: var(--font-family-default);
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: "Expressway";
  src: local("Expressway"),
    url(../../static/media/Expressway.d6e8d7ac.eot) format("eot"),
    url(../../static/media/Expressway.2654e3c2.ttf) format("ttf"),
    url(../../static/media/Expressway.a9e5b805.woff) format("woff");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Expressway";
  src: local("Expressway"),
    url(../../static/media/Expressway.03a683bd.eot) format("eot"),
    url(../../static/media/Expressway.fdb61748.ttf) format("ttf"),
    url(../../static/media/Expressway.fc90b3df.woff) format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Expressway";
  src: local("Expressway"),
    url(../../static/media/expressway.a7df8ece.eot) format("eot"),
    url(../../static/media/expressway.c2b05ecf.ttf) format("ttf"),
    url(../../static/media/expressway.f98ac866.woff) format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Expressway";
  src: local("Expressway"),
    url(../../static/media/ExpresswayLt-Regular.bdb45b42.eot) format("eot"),
    url(../../static/media/ExpresswayLt-Regular.116723c8.ttf) format("ttf"),
    url(../../static/media/ExpresswayLt-Regular.512137d0.woff) format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Expressway";
  src: local("Expressway"),
    url(../../static/media/ExpresswayXl-Regular.a40aa3ea.eot) format("eot"),
    url(../../static/media/ExpresswayXl-Regular.fb31cdec.ttf) format("ttf"),
    url(../../static/media/ExpresswayXl-Regular.58cfec62.woff) format("woff");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: MaxEmoji;
  src: url(../../static/media/Brand-Live-Emoji.757eaf58.otf) format("opentype");
}

.uppercase {
  text-transform: uppercase;
}

