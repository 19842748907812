@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,700;1,400&display=swap");

* {
  box-sizing: border-box;
}

html {
  /* Colors */
  --color-primary: #991b20;
  --color-secondary: #001e2d;

  --color-action-primary: #efc97b;
  --color-action-secondary: #ffffff;

  --font-family-default: "Expressway";
  --font-weight-default: 500;

  --color-text-primary: #ffffff;
  --color-text-secondary: #001e2d;
  --color-text-default: #ffffff;
  --color-text-disabled: #6e6e6e;
  --color-text-input: #000000;

  --color-fill-error: #ffc6c6;
  --color-text-error: #d62d0a;
  --color-text-success: #297f2c;

  --color-border-error: #e45c52;
  --color-border-default: #6e6e6e;

  /* Settings */
  --border-radius: 4px;
}

body {
  font-family: var(--font-family-default);
  font-weight: 400;
}

img {
  max-width: 100%;
  height: auto;
}

a {
  color: white;
}

button {
  font-family: var(--font-family-default);
  font-weight: 300;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  line-height: 135%;
  font-family: var(--font-family-default);
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: "Expressway";
  src: local("Expressway"),
    url("Fonts/Expressway/Bold/Expressway.eot") format("eot"),
    url("Fonts/Expressway/Bold/Expressway.ttf") format("ttf"),
    url("Fonts/Expressway/Bold/Expressway.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Expressway";
  src: local("Expressway"),
    url("Fonts/Expressway/Book/Expressway.eot") format("eot"),
    url("Fonts/Expressway/Book/Expressway.ttf") format("ttf"),
    url("Fonts/Expressway/Book/Expressway.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Expressway";
  src: local("Expressway"),
    url("Fonts/Expressway/Reg/expressway.eot") format("eot"),
    url("Fonts/Expressway/Reg/expressway.ttf") format("ttf"),
    url("Fonts/Expressway/Reg/expressway.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Expressway";
  src: local("Expressway"),
    url("Fonts/Expressway/Lite/ExpresswayLt-Regular.eot") format("eot"),
    url("Fonts/Expressway/Lite/ExpresswayLt-Regular.ttf") format("ttf"),
    url("Fonts/Expressway/Lite/ExpresswayLt-Regular.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Expressway";
  src: local("Expressway"),
    url("Fonts/Expressway/XLite/ExpresswayXl-Regular.eot") format("eot"),
    url("Fonts/Expressway/XLite/ExpresswayXl-Regular.ttf") format("ttf"),
    url("Fonts/Expressway/XLite/ExpresswayXl-Regular.woff") format("woff");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: MaxEmoji;
  src: url("Fonts/Emoji/Brand-Live-Emoji.otf") format("opentype");
}

.uppercase {
  text-transform: uppercase;
}
